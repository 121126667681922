<!--批量生成卡密-->
<template>
    <div class="view">
        <el-form label-width="120px" v-loading="isLoading">
            <el-form-item :label="lang['html.el-form-item.keyNum']">
                <el-input v-model="postData.makeNum" />
            </el-form-item>

            <el-form-item :label="lang['html.el-form-item.money']">
                <el-input v-model="postData.money"/>
            </el-form-item>

            <el-form-item :label="lang['html.el-form-item.nickname']">
                <el-input v-model="postData.nickname" placeholder="(选填)每张卡密的备注"/>
            </el-form-item>

            <el-form-item :label="lang['html.el-form-item.type']">
<!--                卡密类型[type]: 1=注册码 2=充值码-->
                <el-radio-group v-model="postData.type">
                    <el-radio :label="1">{{lang['html.el-radio.1']}}</el-radio>
                    <el-radio :label="2">{{lang['html.el-radio.2']}}</el-radio>
                </el-radio-group>
            </el-form-item>

            <el-form-item label="">
                <el-button type="primary" @click="onSubmit">{{lang['html.button.submit']}}</el-button>
                <el-button @click="this.$router.go(0)">{{lang['html.button.reset']}}</el-button>
            </el-form-item>
        </el-form>
        <el-tag type="info">[Money] {{lang['html.el-tag.reg']}}:{{money.reg}} {{lang['html.el-tag.rec']}}:{{money.rec}}</el-tag>
        <br>
        <br>
        <span style="font-size: 13px;color: #F56C6C">
            Tip: {{lang['html.span.tips.title']}}
            <br>
            {{lang['html.span.tips.reg']}}
            <br>
            {{lang['html.span.tips.rec']}}
        </span>
    </div>
</template>

<script>
export default {
    name: "keyMake",
    data(){
        return {
            //自己剩余余额
            money:{
                reg:-1,//注册
                rec:-2,//充值
            },
            //生成卡密参数
            postData:{
                makeNum:null,//生成数量
                nickname:'',//生成卡密备注
                money:null,//每张卡密额度
                type:1,//卡密类型[type]: 1=注册码 2=充值码
            },
            isLoading:false,
            lang:this.$lang.getLang('key','keyMake')
        }
    },
    methods:{
        //查询自身余额
        getSelfMoney(){
            this.$api.post('Agent.AgentData/getSelfMoney').then(res=>{
                let data = this.$helper.checkRes(res);
                if(!data){
                    this.$alert(this.lang['getSelfMoney.error']);
                    return;
                }
                this.money = {
                    reg:data.money_reg ?? -1,//注册
                    rec:data.money_rec ?? -2,//充值
                }
            }).catch(err=>{
                this.$helper.axiosCatch(err);
            })
        },
        //提交请求
        onSubmit(){
            let data = this.postData;
            //检查参数
            let bool = data.makeNum > 0 && data.makeNum <= 2000;
            if (!bool){
                this.$alert(this.lang['onSubmit.error.keyNum']);
                return;
            }
            bool = data.money > 0 && data.money <= 5000;
            if (!bool){
                this.$alert(this.lang['onSubmit.error.money']);
                return;
            }
            this.isLoading = true;
            this.$message(this.lang['onSubmit.wait']);
            this.$api.post('Key.Make/makeKey',{
                makeNum:data.makeNum,
                nickname:data.nickname,
                money:data.money,
                type:data.type,
            }).then(res=>{
                this.isLoading = false;
                let data = this.$helper.checkRes(res);
                if (data){
                    this.getSelfMoney();
                    this.$alert(this.lang['onSubmit.res.code.100']);
                    return;
                }
                //失败
                let code = this.$helper.getResErrorCode(res);
                this.$helper.alertErrMsg(this.lang,'onSubmit.res.code.',code);
            }).catch(err=>{
                this.isLoading = false;
                this.$helper.axiosCatch(err);
            })
        },
    },
    mounted() {
        this.getSelfMoney();
    }
}
</script>

<style scoped>
.view{
    width: 50%;
    margin:0 auto;
    margin-top: 20px;
}

</style>